<template>
  <div>

    <div class="commonInputTopBox">

      <pageHeader :pagename="'所有车库单位'" :total="total"/>

      <el-form :inline="true" class="demo-form-inline">
        <el-form-item label="搜索车库">
          <el-input placeholder="车库名，车库id，或地址" v-model="query.keyword"></el-input>
        </el-form-item>
        <el-form-item label="车库运行状态">
          <el-select v-model="query.company_status" placeholder="运行状态">
            <el-option label="准备中" value="1"></el-option>
            <el-option label="正常对外服务" value="2"></el-option>
            <el-option label="已停止服务" value="3"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item style="float: right;" >
          <el-button type="primary" @click="getFirstLevel">查询</el-button>
        </el-form-item>
      </el-form>

    </div>
    
      <div class="commonControl-body">
        <common-table
          :tableData="
            tableData
          "
          :tableButton="tableButton"
          :tableLabel="tableLabel"
          @edit="editFirstLevel"
        ></common-table>
        <div class="layoutBorder">
          <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="currentPage"
            :page-sizes="[5, 10, 20, 40]"
            :page-size="pagesize"
            layout="total, sizes, prev, pager, next, jumper"
          >
          </el-pagination>
        </div>
    </div>
  

  </div>
</template>

<script>

import { garage_manage } from "@/api/manage/business/garage/garage.js";
import CommonTable from "frontelementpackage/src/CommonTableButtonFixed.vue";



export default {
  name: "start",
  data() {
    return {
      query: {
        querypage: 1, //获取页码
        pagesize: 10, //每页返回数量
        keyword: "", //搜索 可搜邮箱 用户名 手机号
        company_status: '' //1准备中, 2正常对外服务, 3已停止服务
      },
      //分页数据
      total: 0,
      currentPage: 1, //初始页
      pagesize: 10, //    每页的数据
      tableData: [],
      dialogFormVisibleEdit: false,
      formEdit: { group_info: "" },
      dialogFormVisibleAdd: false,
      formAdd: {},
      tableLabel: [
        {
          prop: "info_name",
          label: "车库名称",
          // width: 150,
          minWidth:"20%"
        },
        {
          prop: "info_address",
          label: "车库地址",
          // width: 150,
          minWidth:"20%"
        },
        {
          prop: "info_phone",
          label: "联系电话",
          // width: 150,
          minWidth:"10%"
        },
       
        {
          prop: "info_introduce",
          label: "车库简介",
          // width: 150,
          minWidth:"20%"
        },
        {
          prop: "company_status",
          label: "车库状态",
          width: 150,
          minWidth:"10%"
        },
        {
          
          prop: "space",
          label: "车位数",
          width: 100,
          minWidth:"10%"
        },
        {
          
          prop: "info_floor",
          label: "楼层",
          width: 100,
          minWidth:"10%"
        }
      ],
      tableButton: {
        width: 100,
        data:[
          {
            name: "查看详情",
            type: "primary",
            size: "small",
          }
        ]
       
      }
    };
  },
  components: {
    CommonTable
  },
  created() {
      this.getFirstLevel();
  },
  methods: {
    editFirstLevel(row,methods) {
      if(methods=="查看详情"){
        // this.$router.push({name:"garage_Manage_Info",query:row})
        this.$router.push({name:"garage_Manage_Info", query:{garage_id:row.garage_id}})
      }
    },
    toInfo(info){
      console.log(info)
    },
    editUser(row) {
      console.log(row,"sss")
      this.formEdit = {
        garage_id: row.garage_id,
        info_name: row.info_name,
        info_phone: row.info_phone,
        info_address:row.info_address,
        info_introduce:row.info_introduce
      };
     
      this.dialogFormVisibleEdit = true;
    },
    delUser(row) {
      let ids = row.id;
      console.log({ group_id: ids });
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.query = {
        querypage: this.currentPage, 
        pagesize: this.pagesize , 
        keyword: "",
        company_status:0
      };
      console.log(this.query)
      //  console.log("当前页面显示xxx条", this.currentPage,"当前界面是xxx页",this.currentPage);
      garage_manage(this.query).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.result;
      });
    },
    handleSizeChange(val) {
      this.pagesize = val; //获取page-sizes里的每页显示几条数据的值，赋给我们自定义的每页显示数量的变量pageNum
      // console.log("当前页面显示xxx条", val,"当前界面是xxx页",this.currentPage);
      this.query = {
        querypage: this.currentPage, 
        pagesize: this.pagesize, 
        keyword: "", 
        company_status:0
      };
      console.log(this.query)
      garage_manage(this.query).then((res) => {
        this.total = res.data.total;
        this.tableData = res.data.result;
      });
    },

    createFirstLevel() {
      // user_group_manage_create(this.formAdd).then((res) => {
      //   if (res.code == 200) {
      //     this.$message.success("成功添加");
      //     window.location.reload();
      //   } else {
      //     this.$message.info(res.msg);
      //   }
      // });
      this.dialogFormVisibleEdit = false;
    },
    addFormButton() {
      this.dialogFormVisibleAdd = true;
    },
    
    getFirstLevel() {
      garage_manage(this.query).then((res) => {
        this.total = res.data.total;
        
        this.tableData = res.data.result;
        
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>